import { Component, OnInit, Input } from '@angular/core';
import { HomeBannerService } from '../../Banner/HomeBanner/HomeBanner.service';

@Component({
  selector: 'app-banner-slider',
  templateUrl: './BannerSlider.component.html',
  styleUrls: ['./BannerSlider.component.scss']
})
export class BannerSliderComponent implements OnInit {

	@Input() bannerSliderConfig : any;

	@Input() bannerSliderContent : any;

  public isLoading = false;

  constructor(private service: HomeBannerService) {}

	ngOnInit() {
    //home banner content
    this.isLoading = true;
    this.service.getSocialList().subscribe(
      (response) => {
        this.bannerSliderContent = response["bannerList"];
      },
      (err) => console.log(err),
      () => this.bannerSliderContent
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
	}

}
