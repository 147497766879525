<div class="row">
   <div class="col-12 col-md-11 col-xl-10 mx-auto">
      <div class="row align-items-center">
         <div class="col-12 col-xl-6 mb-4 mb-xl-0">
            <div class="img-grid-wrap custom-column-grid">
               <div class="grid-item grid-item-space" *ngFor="let image of exploreStudio">
                  <div class="image-mason-item" [ngStyle]="{'background-image': 'url(' + image + ')'}"></div>
               </div>
            </div>
         </div>
         <div class="col-12 col-sm-12 col-md-12 col-xl-6 px-3 px-xl-5 mx-auto">
            <div class="explore-studio-sec">
               <div class="sec-title">
                  <h2 class="grid-item-space d-block title">Our Process</h2>   
               </div>
               <div class="sec-content mrgn-b-2">
                  <h4 class="font-weight-normal lh-14">We are a digital studio agency which beleives in giving high <a class="highlight">User Experience</a>, we do this by applying systematic and consistent approach. </h4>
               </div>
            </div>
            <!-- explore-studio-sec wrap close -->
            <div class="explore-std-btn">
               <a  [routerLink]="['/about-us/process']" class="btn btn-secondary-grad btn-click-effect">See all Work</a>
            </div>
            <!-- explore-std-btn wrap close -->
         </div>
      </div>
   </div>
</div>   