<footer class="footer-section footer-spacing" id="footer-wrap">
	<ng-container *ngIf="currentUrl!='/about-us/about' && currentUrl!='/contact'">
   <div class="subscribe-wrap footer-spacing pt-0">
    <div class="container">
       <div class="row">
          <div class="col-12 col-lg-11 mx-auto">
            <mat-progress-spinner mode="indeterminate" color="accent" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <form class="subscribe-form" [formGroup]="form" *ngIf="!isRegistered">
             <div class="form-group mb-sm-0" *ngIf="!isLoading">
                <input name="name-input" class="form-control search-input" type="email" value="" placeholder='{{ "SIGN_UP_EMAIL_REQIUER" | translate }}' formControlName ='email'>
                <small class="text-danger text-left" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                  {{ "SIGN_UP_EMAIL_REQIUER" | translate }}
                </small>
                <small class="text-danger" *ngIf="form.controls['email'].errors && !(form.controls['email'].hasError('required')) && form.controls['email'].touched">
                  {{ "SIGN_UP_EMAIL_VALID" | translate }}
                </small>
                <small class="text-danger text-center mb-0" *ngIf="errorFound">
                  {{ resMsg }}
                </small>
             </div>
             <div class="subscribe-btn" *ngIf="!isLoading">
              <input type="submit" value='{{ "BTN_SIGN_UP" | translate }}' class="btn btn-click-effect btn-dark" (click)="addToNewsLetter()">
             </div>
          </form>
             <!-- subscribe-form  -->
        </div>
       </div>
       <div class="form-group text-center mb-0" *ngIf="isRegistered && !isLoading">
          <p class="mb-0">{{ resMsg }}</p>
       </div>
    </div>
 </div>
   <!-- subscribe-wrap close -->
	</ng-container>
	<div class="footer-wrap">
		<div class="container">
			<div class="row">
				<div class="col-12 col-sm-12 col-lg-3 grid-item-space mb-lg-0">
					<div class="footer-sec footer-sec-1">
						<div class="footer-widget-area mb-7">
							<ng-container *ngIf="currentUrl=='/home';else elseBlock">
								<app-theme-dark-logo logoClass="footer-logo"></app-theme-dark-logo>
							</ng-container>
							<ng-template #elseBlock>
								<app-theme-light-logo logoClass="footer-logo"></app-theme-light-logo>
							</ng-template>
						</div>
						<div class="footer-widget-area mb-2 mb-lg-4">
							<div class="social-links">
                <mat-progress-spinner mode="indeterminate" color="accent" *ngIf="isLoadingSocial" style="margin: auto"></mat-progress-spinner>
								<app-social-icons *ngIf="!isLoadingSocial" [socialIcons]="socialIcons"></app-social-icons>
							</div>
						</div>
						<div class="footer-widget-area">
							<div class="copyright-info">
								<p class="mb-0">© 2021 BESTDJS. {{ "RIGHTS_RESERVED" | translate }}</p>
							</div>
						</div>
					</div>
					<!-- footer-sec wrap close -->
				</div>
				<div class="col-6 col-sm-3 col-md-4 col-lg-3 footer-top-space grid-item-space" *ngFor="let menuList of footerMenu.getFooterOneMenu()">
					<app-footer-menu [menu]="menuList"></app-footer-menu>
				</div>
				<!-- footer menu   -->
				<div class="col-12 col-sm-6 col-md-4 col-md-4 col-lg-3 footer-top-space grid-item-space">
					<!-- <app-working-hours></app-working-hours> -->
				</div>
			</div>
		</div>
	</div>
	<!-- footer-wrap close -->
</footer>
<!-- footer close -->
