<div class="blog-item">
	<div class="image-wrap mb-4">
		<a href="blog/blog-detail/{{blogList._id}}"><img src="{{blogList.mainImage}}" class="" alt="" width="790" height="510"></a>
	</div>
	<div class="post-content">
		<div class="sec-title mb-3">
			<h5 class="font-weight-bold"><a href="blog/blog-detail/{{blogList._id}}" href="javascript:void(0);" tabindex="-1">{{blogList.title}}</a></h5>
		</div>
		<div class="sec-content mb-3">
			<p>{{blogList.shortAbout}}</p>
		</div>
		<div class="meta-info">
			<span class="author">
				<u><a class="font-smx" href="javascript:void(0);" tabindex="0">{{blogList.creator.fullName}}</a></u> ,
			</span>
			<span class="date">
				{{ "POSTED_ON" | translate }} : {{blogList.createdAt | date: "dd/MM/yyyy"}}
			</span>
		</div>
	</div>
</div>
<!-- blog-item wrap close -->
