<div class="content">
   <div class="services-wrap services-wrap-v1 shape-wrap services-wrap-space sec-pad">
      <div class="subscribe-wrap footer-spacing pt-0">
        <div class="container">
            <app-search-v1></app-search-v1>
        </div>
     </div>
      <!-- shape group close
      <div class="container">
         <div class="services-content-wrap" *ngIf="serviceItems">
            <app-service-grid [serviceItems]="serviceItems.serviceGrid"></app-service-grid>
         </div>
      </div>
      service content wrap -->
   </div>
 <!-- contact-sec and search-contact wrap close  -->
</div>
