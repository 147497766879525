import { Component, OnInit } from "@angular/core";
import { MenuItems } from "../../../Core/Menu/MenuItems/MenuItems";
import { Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DropletService } from "../../../Services/Droplet.service";
import { FooterService } from "./FooterOne.service";
import { NewsLetter } from "./NewsLetter.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer-one",
  templateUrl: "./FooterOne.component.html",
  styleUrls: ["./FooterOne.component.scss"],
})
export class FooterOneComponent implements OnInit {
  currentUrl: any;
  socialIcons: any;
  public form: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private localIp = "";
  public isLoading = false;
  public isLoadingSocial = false;
  public resMsg = "";
  public isRegistered = false;
  public errorFound = false;
  clientData: any;

  constructor(
    public footerMenu: MenuItems,
    private router: Router,
    private fb: FormBuilder,
    private fs: FooterService,
    public service: DropletService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem("clientProfile"));
    this.translate.use(this.clientData.lang);
    this.fs.getUserLocalData().subscribe((res: any) => {
      this.localIp = res.ip;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    this.currentUrl = this.router.url;

    //social icons content
    this.isLoadingSocial = true;
    this.fs.getSocialList().subscribe(
      (response) => {
        this.socialIcons = response["sitesettings"].socialIcons;
      },
      (err) => console.log(err),
      () => this.socialIcons
    );
    setTimeout(() => {
      this.isLoadingSocial = false;
    }, 3000);
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(64),
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  addToNewsLetter() {
    console.log(this.form.value.email);
    let rest = "";
    const newUser = new NewsLetter(this.form.value.email, this.localIp);
    this.fs.addToNewLetter(newUser).subscribe(
      (res) => {
        rest = res["message"];
        this.isRegistered = true;
        this.translate.get("USER_CREATED").subscribe((text: string) => {
          this.resMsg = text;
        });
      },
      (errorMessage) => {}
    );
    this.isLoading = true;
    setTimeout(() => {
      if (!this.isRegistered) {
        this.errorFound = true;
        this.translate.get("USER_NOT_CREATED").subscribe((text: string) => {
          this.resMsg = text;
        });
      }
      console.log(this.resMsg);
      this.isLoading = false;
    }, 3000);
  }
}
