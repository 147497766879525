<div dir="ltr">
   <div class="container">
      <div class="row">
         <app-widget-heading [contentHeading]="clientSaysHeading"></app-widget-heading>
      </div>
      <div class="row">
         <div class="col-12 col-xl-10 mx-auto">
            <div class="testimonial-slider">
            	<app-testimonial [testimonialConfig]="testimonialSlideConfig" [testimonialContent]="testimonialContent"></app-testimonial>
            </div>
            <!-- testimonial-slider wrap close -->
         </div>
      </div>
   </div>
</div>
<!-- ltr wrap close -->