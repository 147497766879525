<div class="banner-sec-wrap">
   <div class="custom-container">
      <div class="pages-common-banner d-flex text-center banner-bg">
         <div class="container">
            <div class="row no-gutters">
               <div class="col-12 col-sm-12 col-md-12 col-lg-10 mx-auto">
                  <nav aria-label="breadcrumb site-breadcrumb text-center">
                     <ol class="breadcrumb justify-content-center mb-0 p-0">
                        <li class="breadcrumb-item"><h6 class="font-weight-bold d-inline-block mb-0"><a class="text-capitalize" href="javascript:void(0)">/ {{currentUrl}}</a></h6></li>
                     </ol>
                  </nav>  
                  <!-- breadcrumb -->
                  <div class="sec-title">
                     <h2 class="mb-0 banner-title">{{pageTitle}}</h2>                          
                  </div>
               </div>   
            </div>                
         </div>
      </div>
      <!-- pages-common-banner  -->
   </div>
</div>
<!-- banner sec wrap close -->