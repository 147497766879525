<form [formGroup]="form" *ngIf="!isRegistered">
   <div class="row">
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder='{{ "CONTACT_FORM_FIRST_NAME" | translate }}' formControlName ='fname'>
         <small class="text-danger" *ngIf="form.controls['fname'].hasError('required') && form.controls['fname'].touched">
          {{ "CONTACT_FORM_FIRST_NAME_REQUIRE" | translate }}
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder='{{ "CONTACT_FORM_LAST_NAME" | translate }}' formControlName ='lname'>
         <small class="text-danger" *ngIf="form.controls['lname'].hasError('required') && form.controls['lname'].touched">
          {{ "CONTACT_FORM_LAST_NAME_REQUIRE" | translate }}
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder='{{ "EMAIL_ADDRESS" | translate }}' formControlName ='email'>
         <small class="text-danger" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
          {{ "SIGN_UP_EMAIL_REQIUER" | translate }}
         </small>
         <small class="text-danger" *ngIf="form.controls['email'].errors && !(form.controls['email'].hasError('required')) && form.controls['email'].touched">
          {{ "SIGN_UP_EMAIL_VALID" | translate }}
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 col-sm-6 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder='{{ "CONTACT_FORM_PHONE" | translate }}' formControlName ='telephone'>
         <small class="text-danger" *ngIf="form.controls['telephone'].hasError('required') && form.controls['telephone'].touched">
          {{ "CONTACT_FORM_PHONE_REQUIRE" | translate }}
         </small>
      </div>
      <!-- form group close -->
      <div class="col-12 form-group mrgn-b-2 mb-lg-5">
         <input type="text" class="form-control" placeholder='{{ "CONTACT_FORM_MESSAGE" | translate }}' formControlName ='message'>
         <small class="text-danger" *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched">
          {{ "CONTACT_FORM_MESSAGE_REQUIRE" | translate }}
         </small>
      </div>
      <!-- form group close -->
     <div class="col-12 form-group mrgn-b-2 mb-lg-5">
        <input type="text" class="form-control" placeholder='{{ "CONTACT_FORM_VALID" | translate }} {{rand}}' formControlName ='checkHuman'>
        <small class="text-danger" *ngIf="form.controls['checkHuman'].hasError('required') && form.controls['checkHuman'].touched">
         {{ "CONTACT_FORM_checkHuman_REQUIRE" | translate }}
        </small>
     </div>
      <!-- form group close -->
      <div class="col-12">
         <button class="btn btn-dark" type="submit" (click)="signUp()">{{ "BTN_SEND" | translate }}</button>
      </div>
   </div>
</form>
<div class="form-group text-center mb-0" *ngIf="isRegistered">
   <mat-progress-spinner mode="indeterminate" color="accent" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
   <p class="mb-0">{{ resMsg }}</p>
</div>
<!-- form close -->
