<div class="sticky-header" id="header-sticky">
   <div class="container">
      <div class="d-flex justify-content-between">
         <div class="logo-wrap align-self-center">
           <app-theme-light-logo logoClass="logo"></app-theme-light-logo>
         </div>
         <div class="align-self-center">
            <div class="header-menu">
               <nav id="sticky-nav" class="main-navigation theme-navbar">
                  <div class="primary-menu">
               	  <app-header-menu stickyClass="flex-row"></app-header-menu>				
                  </div>
               </nav>                                             
            </div>  
            <!-- header-menu wrap close-->
         </div>                                       
      </div>                                    
   </div>                                
</div>
<!-- sticky-header wrap close -->