<mat-progress-spinner mode="indeterminate" color="accent" *ngIf="isLoadingSocial" style="margin: auto"></mat-progress-spinner>
<div class="contact-info-blocks row mb-3" *ngIf="!isLoadingSocial">
  <div class="contact-block col-12 col-sm-6 mb-4">
      <div class="contact-tilte">
         <h5>{{ "ADDRESS_TITLE" | translate }}</h5>
      </div>
      <div class="contact-content">
         <p><i class="fas fa-map-marked-alt"></i> : {{pageData.address}}</p>
      </div>
   </div>
   <!-- contact-block wrap close -->
   <div class="contact-block col-12 col-sm-6 mb-4">
      <div class="contact-tilte">
         <h5>{{ "CONTACT_FORM_PHONE" | translate }}</h5>
      </div>
      <div class="contact-content mb-3">
         <p class="d-inline-block mb-0"><i class="fas fa-phone-square-alt"></i> : <a href="tel:{{pageData.cellphone}}" class="mb-1 d-inline-block">{{pageData.cellphone}}</a></p>
      </div>
      <!-- contact-content wrap close -->
   </div>
   <!-- contact-block wrap close -->
</div>
<div class="contact-info-blocks row mb-3" *ngIf="!isLoadingSocial">
   <div class="contact-block col-12 col-sm-6 mb-4">
    <div class="contact-tilte">
       <h5>{{ "EMAIL_ADDRESS" | translate }}</h5>
    </div>
    <div class="contact-content mb-3">
       <p class="d-inline-block mb-0"><i class="fas fa-at"></i> : <a href="{{pageData.email}}" class="mb-0 d-inline-block">{{pageData.email}}</a></p>
    </div>
   </div>
   <!-- contact-block wrap close -->
   <div class="contact-block col-12 col-sm-6 mb-4">
      <div class="contact-tilte">
         <h5>{{ "SOCIAL_MEDIA" | translate }}</h5>
      </div>
      <div class="contact-content">
         <p><app-social-icons [socialIcons]="pageData.socialIcons"></app-social-icons></p>
      </div>
      <!-- contact-content wrap close -->
   </div>
   <!-- contact-block wrap close -->
</div>
<!-- contact-info-blocks wrap close -->
<div class="sec-title mb-5">
   <h2>{{ "NAV_CONTACT" | translate }}</h2>
</div>
