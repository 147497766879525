<ul class="pagination px-0">
   <li class="page-item disabled">
      <a class="page-link" href="javascript:void(0)" tabindex="-1">{{ "BTN_PREV" | translate }}</a>
   </li>
   <li class="page-item" *ngFor ="let items of paginationItems"><a class="page-link" href="javascript:void(0)">{{items}}</a></li>
   <li class="page-item">
      <a class="page-link" href="javascript:void(0)">{{ "BTN_NEXT" | translate }}</a>
   </li>
</ul>
<!-- pagination wrap close -->
