
                <mat-progress-spinner mode="indeterminate" color="accent" *ngIf="isLoadingPS" style="margin: auto"></mat-progress-spinner>
                <form (ngSubmit)="onSubmit()" *ngIf="!isLoadingPS">
                <div class="row" *ngIf="!isLoadingPS">
                  <div class="col-12 col-sm-5 mrgn-b-5 mb-lg-5">
                    <input type="text" matInput [matAutocomplete]="arti" placeholder="{{'ARTIST_SEARCH'|translate}}" [formControl]="artControl" class="form-control" [value]="artControl.value">
                    <mat-autocomplete #arti="matAutocomplete" [displayWith]="displayFnArt" style="background-color: white; color: black;">
                      <mat-option *ngFor="let art of filteredArts | async" [value]="art" style="background-color: white; color: black;">
                          {{art}}
                          <!-- {{item.title | translate}} -->
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                    <div class="col-12 col-sm-5 mrgn-b-5 mb-lg-5">
                      <input type="text" matInput [matAutocomplete]="auto" placeholder="{{'TAG_SEARCH'|translate}}" [formControl]="tagControl" class="form-control" [value]="tagControl.value">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" style="background-color: white; color: black;">
                        <mat-option *ngFor="let item of filteredTags | async" [value]="item" style="background-color: white; color: black;">
                            {{item}}
                            <!-- {{item.title | translate}} -->
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                   <div class="form-group col-12 col-sm-1 mrgn-b-2 mb-lg-2 mb-0">
                    <input class="btn btn-dark btn-click-effect d-inline-block" type="submit" value='{{ "BTN_SEARCH" | translate }}'>
                   </div>
                  </div>
                </form>
                <!-- <form  [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isLoadingPS">
                  <div class="row">
                   <div class="form-group col-12 col-sm-3 mrgn-b-2 mb-lg-5">
                     <input name="name-input" class="form-control" type="text" value="" placeholder='{{ "ARTIST_NAME" | translate }}' formControlName="fullName" style="background-color: #602DAC;">
                   </div>
                   <div class="form-group col-12 col-sm-4 mrgn-b-2 mb-lg-5">
                      <mat-select placeholder="{{'HOME_SELECT_AREA'|translate}}" formControlName="areaOfActivity" class="form-control" style="background-color: #602DAC;">
                        <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'title'" [array]="psArray" (filteredReturn)="filteredListPS = $event"></mat-select-filter>
                        <mat-option *ngFor="let item of filteredListPS" [value]="item" style="background-color: white; color: black;">
                           {{item.title|translate}}
                        </mat-option>
                     </mat-select>
                   </div>
                   <div class="form-group col-12 col-sm-4 mrgn-b-2 mb-lg-5">
                    <mat-select placeholder="{{'SOCIAL_ICONS_TITLE'|translate}}" formControlName="skill" class="form-control" style="background-color: #602DAC;">
                      <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'title'" [array]="SkillArray" (filteredReturn)="filteredListSkill = $event"></mat-select-filter>
                      <mat-option *ngFor="let item of filteredListSkill" [value]="item" style="background-color: white; color: black;">
                         {{item.title|translate}}
                      </mat-option>
                   </mat-select>
                   </div>
                   <div class="form-group col-12 col-sm-1 mrgn-b-2 mb-lg-2 mb-0">
                    <input class="btn btn-dark btn-click-effect d-inline-block" type="submit" value='{{ "BTN_SEARCH" | translate }}' style="background-color: #602DAC;" >
                   </div>
                 </div>
                </form> -->
<!-- search-widget-form wrap close -->
