import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./Main/Main.component";
import { HomeComponent } from "./Pages/Home/Home.component";
import { SignUpComponent } from "./Pages/SignUp/SignUp.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "signup",
        component: SignUpComponent,
      },
      {
        path: "about",
        loadChildren: () =>
          import("./Pages/About/About.module").then((m) => m.AboutModule),
      },
      // {
      //   path: "contact",
      //   component: ContactComponent,
      // },
      {
        path: "contact",
        loadChildren: () =>
          import("./Pages/Contact/Contact.module").then((m) => m.ContactModule),
      },
      {
        path: "artist",
        loadChildren: () =>
          import("./Pages/Artist/Artist.module").then((m) => m.ArtistModule),
      },
      {
        path: "chart",
        loadChildren: () =>
          import("./Pages/Chart/Chart.module").then((m) => m.ChartModule),
      },
      {
        path: "playlist",
        loadChildren: () =>
          import("./Pages/Playlist/Playlist.module").then((m) => m.PlaylistModule),
      },
      {
        path: "business",
        loadChildren: () =>
          import("./Pages/Business/Business.module").then((m) => m.BusinessModule),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./Pages/Blog/Blog.module").then((m) => m.BlogModule),
      },
      {
        path: "search",
        loadChildren: () =>
          import("./Pages/Search/Search.module").then((m) => m.SearchModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: "legacy" }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
