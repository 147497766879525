<div class="container">
   <div class="row">
      <div class="col-12 col-lg-6 grid-item-space">
        <app-contact-content-v1 [contactContent]="contactContent"></app-contact-content-v1>
      </div>
      <!-- <div class="col-12 ml-xl-auto col-lg-5">
        <app-contact-form></app-contact-form>
      </div> -->
   </div>
</div>
