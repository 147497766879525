<ul class="navbar-nav mr-n3 mx-lg-auto px-0 {{ stickyClass }}">
  <li
    class="nav-item"
    [ngClass]="{ 'menu-item-has-child': menuItem.type == 'sub' }"
    *ngFor="let menuItem of menuItems.getMainMenu()"
  >
    <a
      class="nav-link"
      [routerLink]="['/', menuItem.state]"
      routerLinkActive="active-link"
      *ngIf="menuItem.type == 'link'"
      >{{ menuItem?.name | translate }}
    </a>
  </li>
</ul>
