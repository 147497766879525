<div class="counter-sec-wrap sec-pad">
   <div class="container">
      <div class="row">
         <div class="col-12 col-sm-6 col-md-6 col-lg-3 align-self-center" *ngFor="let content of CounterContent">
            <div class="counter-content text-center">
               <div class="icon-circle mb-2">
                  <i class="fa fa-circle mr-1 font-size-sm" aria-hidden="true"></i>
                  <i class="fa fa-circle mr-1 font-size-sm" aria-hidden="true"></i>
                  <i class="fa fa-circle mr-1 font-size-sm" aria-hidden="true"></i>
                  <i class="fa fa-circle font-size-sm" aria-hidden="true"></i>
               </div>
               <div class="text-foreground">
                  <div class="number-wrap">
                     <span class="number-count font-weight-bold" data-count="2300" id="value">{{content.number}}</span>
                  </div>
                  <div class="text-wrap">
                     <span class="text-title font-w-600">{{content.title}}</span>
                  </div>
               </div> 
               <!-- text-foreground wrap close -->
            </div>
            <!-- counter-content wrap close -->
         </div>  
      </div>   
   </div>
</div>
<!-- counter-sec-wrap close -->