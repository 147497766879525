import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ArtistService {
  private _id: any;

  constructor(private httpService: HttpClient) {}

  setID(id) {
    this._id = id;
  }

  getID() {
    return this._id;
  }

  getUsersList() {
    return this.httpService.get("https://bestdjs.aiosx.co/auth/activeList");
  }

  getSingleUser(id) {
    return this.httpService.get("https://bestdjs.aiosx.co/auth/view/" + this._id);
  }

  getTempUser(id) {
    return this.httpService.get("https://bestdjs.aiosx.co/auth/view/" + id);
  }

  createContact(data, id) {
    return this.httpService.patch(
      "https://bestdjs.aiosx.co/auth/contact/" + id,
      JSON.stringify(data)
    );
  }

  updateUserStoreCount(data, id) {
    return this.httpService.patch(
      "https://bestdjs.aiosx.co/auth/updateStore/" + id,
      JSON.stringify(data)
    );
  }

  getAreaTitle(id) {
    return this.httpService.get("https://bestdjs.aiosx.co/area/title/" + id);
  }

  updateUserCount(id) {
    return this.httpService.delete(
      "https://bestdjs.aiosx.co/auth/updateUser/" + id
    );
  }
}
