import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { fadeInAnimation } from "../../../core/route-animation/route.animation";

import { AreaService } from "../../../Services/Area.service";
import { SkillService } from "../../../Services/Skill.service";
import { BlogService } from "../../../Pages/Blog/Blog.service";
import { TagService } from "../../../Services/Tag.service";
import { ArtistService } from "src/app/Pages/Artist/Artist.service";

@Component({
  selector: "app-search-v1",
  templateUrl: "./SearchV1.component.html",
  styleUrls: ["./SearchV1.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class SearchComponent implements OnInit {
  isLoadingPS: Boolean;
  tagList: string[] = [];
  // tagList: Array<any> = [];
  tagControl = new FormControl();
  tagTemp: Array<any>;
  filteredTags: Observable<Array<any>>;
  isLoadingAr: Boolean;
  artList: string[] = [];
  artControl = new FormControl();
  artTemp: Array<any>;
  filteredArts: Observable<Array<any>>;

  temPs: Array<any>;
  psArray: Array<any> = [];
  public filteredListPS;
  public areaSelected;
  temSkill: Array<any>;
  SkillArray: Array<any> = [];
  public filteredListSkill;
  public form: FormGroup;

  constructor(
    public translate: TranslateService,
    public router: Router,
    private blogService: BlogService,
    private areaService: AreaService,
    private skillService: SkillService,
    private tagService: TagService,
    private fb: FormBuilder,
    private artistService: ArtistService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      fullName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(32),
        ]),
      ],
      areaOfActivity: [null, Validators.compose([Validators.required])],
      tag: [null, Validators.compose([Validators.required])],
      skill: [null, Validators.compose([Validators.required])],
    });
    this.isLoadingPS = true;
    this.tagService.getActiveTagList().subscribe((resData) => {
      this.tagTemp = [...resData["tagList"]];
      this.tagTemp.forEach((item) => {
        this.tagList.push(item.description);
      });
      this.filteredTags = this.tagControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    });
    this.artistService.getUsersList().subscribe((response) => {
      this.artTemp = [...response["userlist"]];
      this.artTemp.forEach((item) => {
        this.artList.push(item.nickName);
      });
      this.filteredArts = this.artControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterArt(value))
      );
    });
    setTimeout(() => {
      this.isLoadingPS = false;
    }, 2000);
  }

  private _filter(value: any): any[] {
    return this.tagList.filter((item) => item.includes(value));
  }

  updateFilter(value) {
    const fil = value;
    const temps = this.temPs.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
  }

  displayFn(value): string {
    return value ? value.description : "";
  }

  private _filterArt(value: any): any[] {
    return this.artList.filter((item) => item.includes(value));
  }

  displayFnArt(value): string {
    return value ? value.nickName : "";
  }

  onSubmit() {
    let isTag = true;
    let _id;
    if (this.artControl.value !== "") {
      this.artTemp.forEach((item) => {
        if (item.nickName === this.artControl.value) {
          isTag = false;
          _id = item._id;
        }
      });
    }
    this.tagTemp.forEach((item) => {
      if (item.description === this.tagControl.value) {
        _id = item._id;
      }
    });
    if (isTag) {
      this.tagService.changeData(_id);
      this.router.navigate(["/search"]);
    } else {
      this.router.navigate(["/artist/details/" + _id]);
    }
  }
}
