import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './FooterMenu.component.html',
  styleUrls: ['./FooterMenu.component.scss']
})
export class FooterMenuComponent implements OnInit {

	@Input() menu : any;

	constructor(
    public translate: TranslateService) { }

	ngOnInit() {
	}

}
