import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppComponent } from './app.component';
import { MainComponent } from './Main/Main.component';
import { HomeComponent } from './Pages/Home/Home.component';
import { AppRoutingModule } from './app-routing.module';
import { WidgetsModule } from './Widgets/Widgets.module';
import { MenuComponent } from './Layouts/Menu/HeaderMenu/HeaderMenu/HeaderMenu.component';
import { HeaderOneComponent } from './Layouts/Header/HeaderOne/HeaderOne.component';
import { TemplateModule } from './Template/Template.module';
import { FooterOneComponent } from './Layouts/Footer/FooterOne/FooterOne.component';
import { FooterMenuComponent } from './Layouts/Menu/FooterMenu/FooterMenu/FooterMenu.component';
import { MenuItems } from './Core/Menu/MenuItems/MenuItems';
import { DropletService } from './Services/Droplet.service';
import { StickyheaderComponent } from './Layouts/Header/StickyHeader/StickyHeader.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SignUpComponent } from './Pages/SignUp/SignUp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthInterceptorService } from "./Services/auth-interceptor.service";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOption } from '@angular/material/core';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
@NgModule({
   declarations: [
      AppComponent,
      MainComponent,
      HomeComponent,
      SignUpComponent,
      MenuComponent,
      HeaderOneComponent,
      FooterOneComponent,
      FooterMenuComponent,
      StickyheaderComponent
   ],
   imports: [
		  CommonModule,
      BrowserAnimationsModule,
      BrowserModule,
      AppRoutingModule,
      WidgetsModule,
      TemplateModule,
      FormsModule,
      ReactiveFormsModule,
      LoadingBarRouterModule,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      LoadingBarModule,
      MatProgressSpinnerModule
   ],
   providers: [
      MenuItems,
      DropletService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
      }
   ],
   exports: [],
   bootstrap: [AppComponent]
})

export class AppModule { }
