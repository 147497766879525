import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { User } from "../Models/user.model";
import { MainUrl } from "../Shared/component/url"

export interface AuthResponseData {
  email: string;
  level: string;
  fullName?: string;
  userId?: string;
  token?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  userData: any;
  isLoggedIn = false;
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpClientService: HttpClient, private router: Router) {}

  /*
   *  getLocalStorageUser function is used to get local user profile data.
   */
  getLocalStorageUser() {
    this.userData = JSON.parse(localStorage.getItem("userProfile"));
    if (this.userData) {
      this.isLoggedIn = true;
      return true;
    } else {
      this.isLoggedIn = false;
      return false;
    }
  }

  getUserInfo() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud._token;
    } else {
      return null;
    }
  }

  /*
   * resetPasswordV2 is used to reset your password
   */
  resetPasswordV2(value) {
    // this.firebaseAuth.auth
    //   .sendPasswordResetEmail(value.email)
    //   .then((value) => {
    //     this.toastr.success("סיסמה חדשה נשלחת לכתובת הדואר האלקטרוני שלך.");
    //     this.router.navigate(["/session/loginV2"]);
    //   })
    //   .catch((err) => {
    //     this.toastr.error(err.message);
    //   });
  }
}
