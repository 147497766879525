import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from "../../../Shared/component/url";

@Injectable({ providedIn: "root" })
export class HomeBannerService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}

  getSocialList() {
    return this.httpService.get("https://bestdjs.aiosx.co/sitesettings/banneractivelist");
  }
}
