import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from '../Shared/component/url';

@Injectable({ providedIn: "root" })
export class SkillService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = "https://bestdjs.aiosx.co/skill/";

  getActiveSkillList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getSingleArea(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

}
