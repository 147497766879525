import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tag',
  templateUrl: './Tag.component.html',
  styleUrls: ['./Tag.component.scss']
})
export class TagComponent implements OnInit {

	@Input() tags : any;

  constructor(public translate: TranslateService) {}

	ngOnInit() {
	}

}
