<div class="banner-sec-wrap">
   <div class="custom-container">
      <div class="pages-common-banner d-flex text-center banner-bg">
         <div class="container">
            <div class="row no-gutters">
               <div class="col-12 col-sm-12 col-md-12 col-lg-10 mx-auto">
                  <div class="sec-title">
                     <h2 class="mb-0 banner-title">{{pageTitle}}</h2>                          
                  </div>
               </div>   
            </div>                
         </div>
      </div>
      <!-- pages-common-banner  -->
   </div>
</div>