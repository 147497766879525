import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { HomeSearch } from "../Models/Search.model";

@Injectable({ providedIn: "root" })
export class SearchService {
  private searchData = new BehaviorSubject<HomeSearch>({
    words: "",
    area: "",
  });
  currenSearchData = this.searchData.asObservable();

  constructor(private httpService: HttpClient) {}

  changeData(data) {
    this.searchData.next(data);
  }

  getSearchList() {
    let words;
    this.currenSearchData.subscribe((res) => {
      words = res;
    });
    const blogSearch = new HomeSearch(
      this.searchData.value.words,
      this.searchData.value.area
    );
    return this.httpService.patch(
      "https://bestdjs.aiosx.co/blog/searchByTags",
      blogSearch
    );
  }
}
