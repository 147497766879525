import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./Pagination.component.html",
  styleUrls: ["./Pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() paginationItems: any;

  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
