import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MenuItems } from "src/app/Core/Menu/MenuItems/MenuItems";
import { FooterService } from "src/app/Layouts/Footer/FooterOne/FooterOne.service";

@Component({
  selector: "app-contact-content-v2",
  templateUrl: "./ContactContentV2.component.html",
  styleUrls: ["./ContactContentV2.component.scss"],
})
export class ContactContentV2Component implements OnInit {
  socialIcons: any;
  isLoadingSocial = false;
  pageData: any;

  constructor(
    public translate: TranslateService,
    public footerMenu: MenuItems,
    private fs: FooterService
  ) {}

  ngOnInit() {
    //social icons content
    this.isLoadingSocial = true;
    this.fs.getSocialList().subscribe(
      (response) => {
        this.pageData = response["sitesettings"];
        this.socialIcons = response["sitesettings"].socialIcons;
      },
      (err) => console.log(err),
      () => this.socialIcons
    );
    setTimeout(() => {
      this.isLoadingSocial = false;
    }, 3000);
  }
}
