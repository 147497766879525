import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ArtistService } from "src/app/Pages/Artist/Artist.service";

import { Contact } from "./contact.model"

@Component({
  selector: "app-leavecomment",
  templateUrl: "./LeaveComment.component.html",
  styleUrls: ["./LeaveComment.component.scss"],
})
export class LeavecommentComponent implements OnInit {
  @Input() clienyID: any;
  public form: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private artistService: ArtistService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      fullName: ["", Validators.required],
      telephone: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ["", Validators.required],
    });
  }

  onSubmit() {
    console.log(this.clienyID);
    const newPS = new Contact(
      this.form.value.fullName,
      this.form.value.telephone,
      this.form.value.email,
      this.form.value.message
    );
    console.log(newPS);
    this.artistService.createContact(newPS, this.clienyID).subscribe(
      (res) => {
        console.log(res);
      },
      (errorMessage) => {
        console.log(errorMessage);
      }
    );
  }
}
