<div class="comment-respond">
   <div id="comments" class="comments-area">
      <div id="respond" class="comment-respond">
         <div class="comment-form-wrap">
            <form class="row" [formGroup]="form">
               <div class="form-group col-12 col-md-6 mrgn-b-2">
                  <input name="name-input" class="form-control" type="text" value="" placeholder='{{ "CONTACT_FORM_FIRST_NAME" | translate }}' formControlName ='fullName'>
                  <small class="text-danger" *ngIf="form.controls['fullName'].hasError('required') && form.controls['fullName'].touched">
                   {{ "CONTACT_FORM_FIRST_NAME_REQUIRE" | translate }}
                  </small>
               </div>
               <!-- form-group -->
               <div class="form-group col-12 col-md-6 mrgn-b-2">
                  <input name="name-input" class="form-control" type="text" value="" placeholder='{{ "CONTACT_FORM_LAST_NAME" | translate }}' formControlName ='telephone'>
                  <small class="text-danger" *ngIf="form.controls['telephone'].hasError('required') && form.controls['telephone'].touched">
                   {{ "CONTACT_FORM_LAST_NAME_REQUIRE" | translate }}
                  </small>
               </div>
               <!-- form-group -->
               <div class="form-group col-12 col-md-12 mrgn-b-2">
                  <input name="name-input" class="form-control" type="email" value="" placeholder='{{ "EMAIL_ADDRESS" | translate }}' formControlName ='email'>
                  <small class="text-danger" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                   {{ "SIGN_UP_EMAIL_REQIUER" | translate }}
                  </small>
                  <small class="text-danger" *ngIf="form.controls['email'].errors && !(form.controls['email'].hasError('required')) && form.controls['email'].touched">
                   {{ "SIGN_UP_EMAIL_VALID" | translate }}
                  </small>
               </div>
               <!-- form-group -->
               <div class="form-group col-12 col-md-12 mb-5">
                  <textarea name="name-input" class="form-control" row="5" value="" placeholder='{{ "CONTACT_FORM_MESSAGE" | translate }}' formControlName ='message'></textarea>
                    <small class="text-danger chankya-block" *ngIf="form.controls['message'].hasError('required') && form.controls['message'].touched">
                      {{ "CONTACT_FORM_MESSAGE_REQUIRE" | translate }}
                    </small>
               </div>
               <!-- form-group -->
               <div class="form-group col-12 col-md-12">
                <button class="btn btn-secondary-grad btn-click-effect d-inline-block" type="submit" (click)="onSubmit()">
                   {{'Request_Callback'|translate}}
                </button>
                  <!-- <input class="btn btn-secondary-grad btn-click-effect d-inline-block" type="submit" value='{{ "Request_Callback" | translate }}'> -->
               </div>
               <!-- form-group -->
            </form>
         </div>
         <!-- comment-form-wrap -->
      </div><!-- #respond -->
   </div>
   <!-- comment-area -->
</div>
<!-- comment-respond wrap close -->
