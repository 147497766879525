export class Contact {
  constructor(
    public fname: string,
    public lname: string,
    public email: string,
    public telephone: string,
    public message: string,
    public ipAddress: string
  ) {}

}
