import { Component, OnInit, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FooterService } from "../Layouts/Footer/FooterOne/FooterOne.service";

@Component({
  selector: "app-main",
  templateUrl: "./Main.component.html",
  styleUrls: ["./Main.component.scss"],
})
export class MainComponent implements OnInit {
  currentUrl: any;
  isRtl: any;
  clientData: any;
  // isRtl: any = "rtl";
  private localIp = "";

  constructor(
    public router: Router,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.addRemoveHeaderClass();
        this.addRemoveFooterClass();
      }
    });
    this.currentUrl = this.router.url;
    this.addRemoveHeaderClass();
    this.addRemoveFooterClass();
    this.clientData = JSON.parse(localStorage.getItem("clientProfile"));
    if (!this.clientData) {
      this.translate.addLangs(["he", "en"]);
      this.translate.setDefaultLang("he");

      const browserLang: string = this.translate.getBrowserLang();
      this.translate.use("he");
      this.isRtl = "rtl";
      localStorage.setItem("clientProfile", JSON.stringify({ lang: "he" , isRtl: "rtl"}));
    }else {
      this.translate.use(this.clientData.lang);
      this.isRtl = this.clientData.isRtl;
    }
    this.clientData = JSON.parse(localStorage.getItem("clientProfile"));
  }

  /**
   *addRemoveHeaderClass method is used to add or remove class on header.
   */
  addRemoveHeaderClass() {
    setTimeout(() => {
      if (
        this.currentUrl != "/session/comingsoon" &&
        this.currentUrl != "/session/maintenance" &&
        this.currentUrl != "/session/login" &&
        this.currentUrl != "/session/signup2"
      ) {
        if (this.currentUrl == "/home") {
          document.getElementById("header-sec").className +=
            " header-transparent";
        } else {
          document
            .getElementById("header-sec")
            .classList.remove("header-transparent");
        }
      }
    }, 0);
  }

  //addRemoveFooterClass method is used to add or remove classes on footer.
  addRemoveFooterClass() {
    setTimeout(() => {
      if (
        this.currentUrl != "/session/signup" &&
        this.currentUrl != "/session/comingsoon" &&
        this.currentUrl != "/session/maintenance" &&
        this.currentUrl != "/session/login" &&
        this.currentUrl != "/session/signup2"
      ) {
        if (this.currentUrl == "/home") {
          document.getElementById("footer-wrap").classList.remove("footer-bg");
        } else {
          document.getElementById("footer-wrap").className += " footer-bg";
        }
      }
    }, 0);
  }

  /**
   * changeDirection method is used to change the direction of layout.
   */
  public changeDirection() {
    this.clientData = JSON.parse(localStorage.getItem("clientProfile"));
    if (this.clientData.isRtl === "rtl") {
      localStorage.setItem("clientProfile", JSON.stringify({ lang: "en" , isRtl: "ltr"}));
      this.isRtl = "ltr";
      this.translate.use("en");
    }else {
      localStorage.setItem("clientProfile", JSON.stringify({ lang: "he" , isRtl: "rtl"}));
      this.isRtl = "rtl";
      this.translate.use("he");
    }
    location.reload();
    console.log(this.clientData);
    // if (this.fs.getDL().rtl == "rtl") {
    //   this.isRtl = "ltr";
    //   this.fs.changeToEN();
    //   this.translate.use(this.fs.getDL().lang);
    // } else {
    //   this.isRtl = "rtl";
    //   this.fs.changeToHE();
    //   this.translate.use(this.fs.getDL().lang);
    // }
  }

  onActivate($event) {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 0);
  }

  @HostListener("window:scroll", ["$event"])
  //onScrollEvent method is used to show or hide the sticky header.
  onScrollEvent($event) {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollTop >= 200) {
      if (
        !document.getElementById("header-sticky").classList.contains("visible")
      ) {
        document.getElementById("header-sticky").className += " visible";
      }
    } else {
      document.getElementById("header-sticky").classList.remove("visible");
    }
  }
}
