<div class="team-founder-info team-founder-space sec-pad">
   <div class="container">
      <div class="row align-items-center">
         <div class="col-12 col-md-6 col-lg-5 align-self-start c-sm-space">
            <div class="image-wrap mb-2 mb-md-0">
               <img class="d-inline-block" src="{{teamFounder.image}}" alt="team image" width="600" height="600">
            </div>
         </div>
         <div class="col-12 col-md-6 col-lg-7  ml-auto">
            <div class="team-info-content pl-md-3 pl-lg-4">
               <div class="sec-title">
                  <div class="sup-title mb-3 mb-md-4">
                     <h6 class="mb-1">Meet the founder of Droplet</h6>
                     <h3 class="mb-0">{{teamFounder.name}}</h3>
                  </div>
               </div>
               <div class="sec-content mrgn-b-2">
                  <p class="lh-17">{{teamFounder.about}}</p>
               </div>
               <div class="social-links">
                 <app-social-icons [socialIcons]="teamFounder.socialIcons"></app-social-icons> 
               </div>
            </div>
            <!-- team-info-content wrap close -->
         </div>
      </div>
   </div>
</div>
<!-- team-founder-info wrap close -->