import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ContactService {

  constructor(private httpService: HttpClient) {}

  createUser(data) {
    console.log(data);
    return this.httpService.put(
      "https://bestdjs.aiosx.co/contact/create",
      JSON.stringify(data)
    );
  }

  getUserLocalData() {
    return this.httpService.get("https://api.ipify.org/?format=json");
  }
}
