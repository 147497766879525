<div class="row">
   <div class="col-12 col-sm-6 col-md-6 col-lg-3 c-md-space" *ngFor="let team of teamContent">
      <div class="team-content text-center">
         <div class="image-wrap mrgn-b-2">
            <a (click)="getTeamContent(team)" href="javascript:void(0);"><img class="rounded-circle d-inline-block" src="{{team.thumbImage}}" alt="team image" width="198" height="198"></a>
         </div>
         <div class="team-title">
            <h5 class="font-weight-bold mb-2"><a (click)="getTeamContent(team)" href="javascript:void(0);">{{team.name}}</a></h5>
            <span class="text-muted">{{team.designation}}</span>
         </div>
      </div>
      <!-- team-content wrap close -->
   </div>
</div>   