
<!-- <div class="content">
  <div class="text-center shape-wrap">
  <div class="row align-items-center justify-content-between border-bottom">

  <div class="col-12 col-sm-6">
    {{portfolioContent.title}}
</div>
<div class="col-12 col-sm-6">
  {{portfolioContent.category}}
</div>

</div>
</div>
</div> -->
<div class="content">
  <div class="contact-sec search-contact text-center ">
    <div class="row">
      <div class="col-12 col-lg-12">
          <div class="contact-form-wrap card">
            <div class="row">
              <div class="col-12 col-sm-12">
                {{portfolioContent.category}}
              </div>
            </div>
          </div>
          <!-- contact-form-wrap close -->
      </div>
    </div>
  </div>
</div>
