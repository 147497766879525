import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { BlogSearch } from "./BlogSearch.model";

@Injectable({ providedIn: "root" })
export class BlogService {
  private searchData = new BehaviorSubject<string>("");
  currenSearchData = this.searchData.asObservable();
  private _id: any;

  constructor(private httpService: HttpClient, public router: Router) {}

  setID(id) {
    this._id = id;
  }

  getID() {
    return this._id;
  }

  changeData(data) {
    this.searchData.next(data);
  }

  getBlogList() {
    return this.httpService.get("https://bestdjs.aiosx.co/blog/activelist");
  }

  getSingleBlog(id) {
    const _id = id;
    return this.httpService.get("https://bestdjs.aiosx.co/blog/view/" + this._id);
  }

  getSearchList() {
    let words: string;
    this.currenSearchData.subscribe((res) => {
      words = res;
    });
    if (words.length == 0) {
      this.router.navigate(["/blog"])
    }
    const blogSearch = new BlogSearch(words);
    return this.httpService.patch(
      "https://bestdjs.aiosx.co/blog/searchByTags",
      JSON.stringify(blogSearch)
    );
  }
}
