<div class="wid-post-wrap d-flex">
   <div class="image-wrap">
      <a href="blog/blog-detail/{{postList._id}}"><img class="rounded" src="{{postList.mainImage}}" width="100" height="100"></a>
   </div>
	<div class="post-content pl-3">
		<div class="sec-title">
			<h6 class="mb-2">
				<a href="blog/blog-detail/{{postList._id}}">{{postList.title}}</a>
			</h6>
		</div>
		<div class="meta-info "><span class="post-time"><a href="javascript:void(0);" class="font-smx text-muted"><time datetime="">{{postList.createdAt | date: "dd/MM/yyyy"}}</time></a></span></div>
	</div>
   <!-- post-content wrap close -->
</div>
<!-- wid-post-wrap close -->
