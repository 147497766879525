import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { MainUrl } from '../Shared/component/url';

@Injectable({ providedIn: "root" })
export class TagService {
  private mainUrl: MainUrl = new MainUrl();
  domain = "https://bestdjs.aiosx.co/tag/";

  private searchData = new BehaviorSubject<string>("");
  currenSearchData = this.searchData.asObservable();

  constructor(private httpService: HttpClient) {}

  changeData(data) {
    this.searchData.next(data);
  }

  getActiveTagList() {
    return this.httpService.get(this.domain + "taglist");
  }

  getSingleTag() {
    let _id;
    this.currenSearchData.subscribe((res) => {
      _id = res;
    });
    return this.httpService.get(
      this.domain + "single/" + _id
    );
  }

}
