<form [formGroup]="form" (ngSubmit)="onSubmit()" class="search-widget-form">
  <div class="form-group">
     <input name="name-input" class="form-control search-input" type="search" placeholder='{{ "SEARCH_FORM_TITLE" | translate}}' formControlName="keywords">
  </div>
  <!-- form group wrap close -->
  <div class="subscribe-btn search-btn">
     <input type="submit" value='{{ "BTN_SEARCH" | translate }}' class="btn btn-dark">
  </div>
  <!-- subscribe-btn wrap close -->
</form>
<!-- search-widget-form wrap close -->
