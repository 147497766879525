import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { MainUrl } from "../../../Shared/component/url";

export class DirLang {
  rtl: string;
  lang: string;
}

@Injectable({ providedIn: "root" })
export class FooterService {
  private dl: DirLang = new DirLang();
  private mainUrl: MainUrl = new MainUrl();
  private searchData = new BehaviorSubject<String>("rtl");
  currenSearchData = this.searchData.asObservable();
  constructor(public translate: TranslateService, private httpService: HttpClient) {}

  setDir() {
    this.translate.addLangs(["he", "en"]);
    this.translate.setDefaultLang("he");

    const browserLang: string = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/he/) ? browserLang : "he");
    this.changeToHE()
  }

  changeToEN() {
    this.dl.lang="en";
    this.dl.rtl="ltr"
    localStorage.setItem("userProfile", JSON.stringify(this.dl));
  }

  changeToHE() {
    this.dl.lang="he";
    this.dl.rtl="rtl"
    localStorage.setItem("userProfile", JSON.stringify(this.dl));
  }

  getDL() {
    return JSON.parse(localStorage.getItem("userProfile"));
  }

  changeDir(data) {
    this.searchData.next(data);
  }

  getDir() {
    return this.searchData;
  }

  getSocialList() {
    return this.httpService.get(
      "https://bestdjs.aiosx.co/sitesettings/view/6081fa84b6685ff2ddda6c21"
    );
  }

  addToNewLetter(data) {
    return this.httpService.put(
      this.mainUrl.url + "newsletter/create",
      JSON.stringify(data)
    );
  }

  getUserLocalData() {
    return this.httpService.get("https://api.ipify.org/?format=json");
  }
}
