import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ContactService } from "./contact.service";
import { Contact } from "./contact.model";

@Component({
  selector: "app-contact-form-v2",
  templateUrl: "./ContactFormV2.component.html",
  styleUrls: ["./ContactFormV2.component.scss"],
})
export class ContactFormV2Component implements OnInit {
  public form: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private localIp = "";
  public isLoading = false;
  public resMsg = "";
  public isRegistered = false;
  public rand;

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.contactService.getUserLocalData().subscribe((res: any) => {
      this.localIp = res.ip;
    });

    this.rand = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111;

    this.form = this.fb.group({
      fname: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ]),
      ],
      lname: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ]),
      ],
      message: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ]),
      ],
      telephone: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ]),
      ],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(64),
          Validators.pattern(this.emailPattern),
        ]),
      ],
      checkHuman: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  //signUp method is used when form field is valid then router will be navigate to the home page.
  signUp() {
    if (this.rand == this.form.value.checkHuman) {
      console.log("eq")
    }else {

    }
    let rest = "";
    const newUser = new Contact(
      this.form.value.fname,
      this.form.value.lname,
      this.form.value.email,
      this.form.value.telephone,
      this.form.value.message,
      this.localIp
    );
    this.contactService.createUser(newUser).subscribe(
      (res) => {
        rest = res["message"];
        this.isRegistered = true;
        this.translate.get("Contact_created_successfully").subscribe((text: string) => {
          this.resMsg = text;
        });
      },
      (errorMessage) => {}
    );
    this.isLoading = true;
    setTimeout(() => {
      if (!this.isRegistered) {
        this.translate.get("Contact_not_created").subscribe((text: string) => {
          this.resMsg = text;
        });
      }
      console.log(this.resMsg);
      this.isLoading = false;
    }, 3000);
  }
}
