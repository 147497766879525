import { Component, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-lets-talk",
  templateUrl: "./LetsTalk.component.html",
  styleUrls: ["./LetsTalk.component.scss"],
})
export class LetsTalkComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
