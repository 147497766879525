import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
	state: string;
	name: string;
	type?: string;
}

const HEADERMENUITEMS = [
	{
		state: "artist",
		name: "NAV_ARTISTS",
		type:"link"
	},
	{
		state: "chart",
		name: "NAV_CHART",
		type:"link"
	},
	{
		state: "blog",
		name: "NAV_BLOG",
		type:"link"
	},
	{
		state: "playlist",
		name: "NAV_PLAYLIST",
		type:"link"
	},
	{
		state: "business",
		name: "NAV_BUSINESS",
		type:"link"
	},
	{
    state: 'signup',
    name: "NAV_SIGNUP",
    type: 'link'
	},
	{
		state: "about",
		name: "NAV_ABOUT",
		type:"link"
	},
	{
		state: "contact",
		name: "NAV_CONTACT",
		type:"link"
	}

]

const FooterOneItems= [
	{
		state:'',
		name:"BESTDJ'S",
		type:"sub",
		icon: '',
		children: [
			{
				state: 'home',
				name: 'FOOTER_HOME',
				type: 'link'
			},
			{
				state: 'about',
				name: 'FOOTER_ABOUT',
				type: 'link'
			},
			{
				state: 'contact',
				name: 'NAV_CONTACT',
				type: 'link'
			}
		]
	},
	{
		state:'',
		name:"QUICK_LINKS",
		type:"sub",
		icon: '',
		children: [
			{
        state: "artists",
        name: "NAV_ARTISTS",
        type:"link"
			},
			{
        state: "blog",
        name: "NAV_BLOG",
        type:"link"
			},
			{
				state: 'session/signup',
        name: "NAV_SIGNUP",
				type: 'link'
			}
		]
	}
]

@Injectable()
export class MenuItems {

	/*
	 * Get all header menu
	 */
	getMainMenu(): Menu[] {
		return HEADERMENUITEMS;
	}

	/*
	 * Get all footer menu
	 */
	getFooterOneMenu(): Menu[] {
		return FooterOneItems;
	}
}
