import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BlogService } from "src/app/Pages/Blog/Blog.service";

@Component({
  selector: "app-search-v2",
  templateUrl: "./SearchV2.component.html",
  styleUrls: ["./SearchV2.component.scss"],
})
export class SearchV2Component implements OnInit {
  public form: FormGroup;
  @Input() btnContent: any;

  constructor(
    private blogService: BlogService,
    private fb: FormBuilder,
    public router: Router
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      keywords: [null],
    });
  }

  onSubmit() {
    this.blogService.changeData(this.form.value.keywords);
    this.router.navigate(["/blog/blog-search"]);
  }
}
