import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { User } from "../../Models/user.model";
import { ResponseMessage } from "src/app/Shared/response-message.model";
import { UserService } from "./user.service";

@Component({
  selector: "app-signup",
  templateUrl: "./SignUp.component.html",
  styleUrls: ["./SignUp.component.scss"],
})
export class SignUpComponent implements OnInit {
  public form: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  private localIp = "";
  public isLoading = false;
  public resMsg = "";
  public isRegistered = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.userService.getUserLocalData().subscribe((res: any) => {
      this.localIp = res.ip;
    });
    this.form = this.fb.group(
      {
        fullName: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(7),
            Validators.maxLength(24),
          ]),
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(24),
          ]),
        ],
        confirmPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(24),
          ],
        ],
        email: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(64),
            Validators.pattern(this.emailPattern),
          ]),
        ],
      },
      {
        validator: this.MatchPassword, // your validation method
      }
    );
  }

  /*
   * Match Password Custom validation
   */
  MatchPassword(AC: AbstractControl) {
    let password = AC.get("password").value; // to get value in input tag
    let confirmPassword = AC.get("confirmPassword").value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get("confirmPassword").setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }

  //signUp method is used when form field is valid then router will be navigate to the home page.
  signUp() {
    let rest = "";
    const newUser = new User(
      this.form.value.email,
      this.form.value.password,
      this.form.value.fullName,
      this.localIp
    );
    this.userService.createUser(newUser).subscribe(
      (res) => {
        rest = res["message"];
        this.isRegistered = true;
        this.translate.get("USER_CREATED").subscribe((text: string) => {
          this.resMsg = text;
        });
      },
      (errorMessage) => {}
    );
    this.isLoading = true;
    setTimeout(() => {
      if (!this.isRegistered) {
        this.translate.get("USER_NOT_CREATED").subscribe((text: string) => {
          this.resMsg = text;
        });
      }
      this.isLoading = false;
    }, 3000);
  }
}
