<div class="subscribe-wrap footer-spacing pt-0">
   <div class="container">
      <div class="row">
         <div class="col-12 col-lg-11 mx-auto">
   			<form class="subscribe-form">
   			   <div class="form-group mb-sm-0">
   			      <input name="name-input" class="form-control search-input" type="search" value="" placeholder="Enter your email address">
   			   </div>
   			   <div class="subscribe-btn">
   			      <input type="submit" value='sub' class="btn btn-click-effect btn-dark">
   			   </div>
   			</form>
            <!-- subscribe-form  -->
		   </div>
      </div>
   </div>
</div>
<!-- subscribe-wrap close -->
