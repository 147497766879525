import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FooterService } from "./Layouts/Footer/FooterOne/FooterOne.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor(translate: TranslateService, private fs: FooterService) {
    // this.fs.setDir();
    // translate.addLangs(["he", "en"]);
    // translate.setDefaultLang("he");

    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/he/) ? browserLang : "he");
  }
}
