<ng-container *ngIf="aboutAuthor">
   <div class="sec-title">
      <h4 class="mrgn-b-2">{{aboutAuthor.nickName}}</h4>
      <h6 class="mrgn-b-2">{{aboutAuthor.fullName}}</h6>
   </div>
   <div class="row post-author-info-wrap">
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 blog-author-info-img">
         <div class="image-wrap mb-4">
            <img class="rounded" src="{{aboutAuthor.image}}" alt="" width="160" height="160">
         </div>
      </div><!-- blog-author-info-img -->
      <div class="col-12 col-sm-12 col-md-9 col-lg-9 align-self-center blog-author-info-content mb-4">
         <div class="author-post-name">
            <div class="sec-content">
              <blockquote>
                <p class="font-weight-bold font-mdx">{{aboutAuthor.short_about}}</p>
              </blockquote>
            </div>
         </div><!-- author-post-name -->
      </div><!-- blog-author-info-content -->
   </div>
   <!-- post-author-info-wrap close -->
</ng-container>
