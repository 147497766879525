<div class="footer-sec footer-sec-2">
   <div class="footer-widget-area working-wrap">
      <div class="footer-menu-list">
         <div class="footer-sec-title pb-3">
            <h6>Working Hours</h6>
         </div>
         <div class="footer-menu footer-menu2">
            <div class="timing-widget">
               <div class="d-flex justify-content-sm-between">
                  <div class="timing-title mr-3 mr-sm-0">
                     Mon Tue:
                  </div>
                  <div class="timing-content">
                     6 Am - 10 Pm
                  </div>
               </div>
               <div class="d-flex justify-content-sm-between">
                  <div class="timing-title mr-3 mr-sm-0">
                     Wed - Thus:
                  </div>
                  <div class="timing-content">
                     8 Am - 6 Pm
                  </div>
               </div>
               <div class="d-flex justify-content-sm-between">
                  <div class="timing-title mr-3 mr-sm-0">
                     Fri - Sat:
                  </div>
                  <div class="timing-content">
                     3 Pm - 8 Pm
                  </div>
               </div>
               <div class="d-flex justify-content-sm-between">
                  <div class="timing-title mr-3 mr-sm-0">
                     Sunday
                  </div>
                  <div class="timing-content">
                     Closed
                  </div>
               </div>
            </div>
         </div>
         <!-- footer-menu wrap close -->
      </div>
      <!-- footer-menu-list wrap close -->
   </div>
</div>
<!-- footer-sec wrap close -->
