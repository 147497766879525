<div class="widget-panel-info">
  <h5 class="widget-title">{{ "MUSIC_STYLE" | translate }}</h5>
</div>
<div class="tagcloud mb-2 mr-2 d-inline-block" *ngFor="let tag of tags">
  <a
    href="javascript:void(0)"
    class="tag-cloud-link px-3 py-2 bg-light rounded d-inline-block border"
    >{{ tag.title }}</a
  >
</div>
<!-- tagcloud wrap close -->
