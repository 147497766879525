import { Component, OnInit } from "@angular/core";
import { HomeBannerService } from "./HomeBanner.service";

@Component({
  selector: "app-home-banner",
  templateUrl: "./HomeBanner.component.html",
  styleUrls: ["./HomeBanner.component.scss"],
})
export class HomeBannerComponent implements OnInit {
  bannerContent: any;

  bannerConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    cssEase: "linear",
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  constructor(private service: HomeBannerService) {}

  ngOnInit() {
  }
}
