<div class="contact-content">
   <div class="sec-title mrgn-b-2 mb-lg-5">
      <h3 class="mb-4">{{contactContent.title}}</h3>
      <p>{{contactContent.content}}</p>
   </div>
   <div class="contact-info d-flex align-items-center mb-4">
      <div class="image-wrap mr-4">
         <img class="rounded-circle" src="{{contactContent.image}}" alt="contact image" width="100" height="100">
      </div>
      <div class="sec-content">
         <p class="email-wrap d-block mb-0">
            <a href="mailto:{{contactContent.email}}">{{contactContent.email}}</a>
         </p>
         <p class="phone-no d-block mb-1">
            <a href="tel:{{contactContent.contactTo}}">{{contactContent.contactNumber}}</a>
         </p>
      </div>
   </div>
   <!-- contact info wrap close-->
</div>	
<!-- contact-content wrap close -->
